<template>
    <div class="CustomerEdit">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-row>
                <el-col :span="8">
                    <el-form
                        ref="updateForm"
                        :model="customer"
                        label-width="80px"
                        @submit.native.prevent
                        size="small"
                        :rules="rules"
                    >
                        <el-form-item label="编码">
                            <el-input v-model="customer.code" readonly />
                        </el-form-item>
                        <el-form-item label="客户名称" prop="name">
                            <el-input v-model="customer.name" />
                        </el-form-item>
                        <el-form-item label="联系人" prop="linkman">
                            <el-input v-model="customer.linkman" />
                        </el-form-item>
                        <el-form-item label="手机号" prop="mobile">
                            <el-input v-model="customer.mobile" />
                        </el-form-item>
                        <el-form-item label="地址">
                            <el-input v-model="customer.address" />
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import { mobileValidateRule } from 'js/validate/ValidateCommonRule';
import { putAfterConfirmed } from 'request/http';

export default {
    name: 'CustomerEdit',
    props: ['form'],
    data() {
        return {
            customer: {},
            rules: {
                name: { required: true, message: '请输入客户姓名', trigger: ['change', 'blur'] },
                linkman: { required: true, message: '请输入联系人', trigger: ['change', 'blur'] },
                mobile: mobileValidateRule(),
            },
        };
    },
    mounted() {
        this.customer = {
            code: this.form.code,
            name: this.form.name,
            linkman: this.form.linkman,
            mobile: this.form.mobile,
            address: this.form.address,
        };
    },
    methods: {
        handleSave() {
            this.$refs.updateForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                await putAfterConfirmed('/advert/customer/update_c/' + this.form.code, this.customer);
                this.goBackAndReload();
            });
        },
    },
};
</script>
